var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_bank") } },
        [
          _c(
            "a-tabs",
            { attrs: { type: "card" } },
            [
              _c(
                "a-tab-pane",
                {
                  key: "1",
                  staticClass: "p-3",
                  attrs: { tab: _vm.$t("lbl_master_bank") }
                },
                [
                  _vm.hasPrivilegeCreate
                    ? _c(
                        "a-row",
                        { attrs: { gutter: [16, 16] } },
                        [
                          _c(
                            "a-col",
                            [
                              _c(
                                "a-card",
                                [
                                  _c("h2", [
                                    _vm._v(_vm._s(_vm.$t("lbl_bank")))
                                  ]),
                                  _c(
                                    "a-form",
                                    {
                                      attrs: {
                                        layout: "vertical",
                                        form: _vm.form,
                                        "label-col": { span: 8 },
                                        "wrapper-col": { span: 14 }
                                      }
                                    },
                                    [
                                      _c(
                                        "a-row",
                                        { attrs: { gutter: [16, 16] } },
                                        [
                                          _c(
                                            "a-col",
                                            {
                                              attrs: {
                                                span: 12,
                                                xs: 24,
                                                sm: 24,
                                                md: 24,
                                                lg: 12
                                              }
                                            },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.formRules.name.label
                                                  }
                                                },
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value:
                                                          _vm.formRules.name
                                                            .decorator,
                                                        expression:
                                                          "formRules.name.decorator"
                                                      }
                                                    ],
                                                    attrs: {
                                                      name:
                                                        _vm.formRules.name.name,
                                                      placeholder:
                                                        _vm.formRules.name
                                                          .placeholder,
                                                      autocomplete: "off"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.formRules.code.label
                                                  }
                                                },
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value:
                                                          _vm.formRules.code
                                                            .decorator,
                                                        expression:
                                                          "formRules.code.decorator"
                                                      }
                                                    ],
                                                    attrs: {
                                                      name:
                                                        _vm.formRules.code.name,
                                                      placeholder:
                                                        _vm.formRules.code
                                                          .placeholder,
                                                      autocomplete: "off"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            {
                                              attrs: {
                                                span: 12,
                                                xs: 24,
                                                sm: 24,
                                                md: 24,
                                                lg: 12
                                              }
                                            },
                                            [
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.formRules.country
                                                        .label
                                                  }
                                                },
                                                [
                                                  _c("a-input", {
                                                    directives: [
                                                      {
                                                        name: "decorator",
                                                        rawName: "v-decorator",
                                                        value:
                                                          _vm.formRules.country
                                                            .decorator,
                                                        expression:
                                                          "formRules.country.decorator"
                                                      }
                                                    ],
                                                    attrs: {
                                                      name:
                                                        _vm.formRules.country
                                                          .name,
                                                      placeholder:
                                                        _vm.formRules.country
                                                          .placeholder,
                                                      autocomplete: "off"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "a-form-item",
                                                {
                                                  attrs: {
                                                    label:
                                                      _vm.formRules.active.label
                                                  }
                                                },
                                                [
                                                  _c("a-switch", {
                                                    model: {
                                                      value: _vm.isActive,
                                                      callback: function($$v) {
                                                        _vm.isActive = $$v
                                                      },
                                                      expression: "isActive"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "a-form-item",
                                                [
                                                  _c(
                                                    "a-space",
                                                    [
                                                      _c(
                                                        "a-button",
                                                        {
                                                          attrs: {
                                                            type: "danger"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleReset
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "lbl_reset"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _vm.hasPrivilegeCreate
                                                        ? _c(
                                                            "a-button",
                                                            {
                                                              attrs: {
                                                                icon: "plus",
                                                                type: "primary",
                                                                "html-type":
                                                                  "submit",
                                                                loading:
                                                                  _vm.loadingCreate
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.submitForm(
                                                                    "create"
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "lbl_create_new"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 10 } },
                        [
                          _c("InputSearch", {
                            on: { "input-search": _vm.reponseSearchInput }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 6 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.resetFilter()
                                }
                              }
                            },
                            [
                              _c("a-icon", {
                                staticClass: "icon-reload",
                                attrs: { type: "reload" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("TableCustom", {
                            attrs: {
                              idtable: "table1",
                              "selected-row-keys": _vm.selectedRowKeys,
                              "data-source": _vm.dataSource,
                              columns: _vm.columnsTableMaster,
                              scroll: { x: "calc(400px - 50%)", y: 520 },
                              paginationcustom: true,
                              "default-pagination": false,
                              loading: _vm.loadingTableMaster
                            },
                            on: { "on-edit": _vm.responseEditTableMaster }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c("Pagination", {
                            attrs: {
                              total: _vm.totalElementsBank,
                              "page-size-set": _vm.limitBank,
                              "id-pagination": "pagination1"
                            },
                            on: {
                              "response-pagesize-change":
                                _vm.responsePageSizeChangeBank,
                              "response-currentpage-change":
                                _vm.responseCurrentPageChangeBank
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12, align: "end" } },
                        [
                          _c("a-tag", { attrs: { color: "grey" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lbl_total_found")) +
                                " : " +
                                _vm._s(_vm.totalElementsBank) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-tab-pane",
                {
                  key: "2",
                  staticClass: "p-3",
                  attrs: { tab: _vm.$t("lbl_list_of_companys_bank") }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 10 } },
                        [
                          _c("InputSearch", {
                            on: {
                              "input-search": _vm.reponseSearchInputCompanyBank
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 6 } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.resetFilterCompanyBank()
                                }
                              }
                            },
                            [
                              _c("a-icon", {
                                staticClass: "icon-reload",
                                attrs: { type: "reload" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { sm: 24, md: 8, align: "end" } },
                        [
                          _vm.authPrivilege.includes("create") &&
                          _vm.$store.state.innerWidth >= 768
                            ? _c(
                                "a-button",
                                {
                                  attrs: { icon: "plus", type: "primary" },
                                  on: { click: _vm.handleAddNew }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.authPrivilege.includes("create") &&
                          _vm.$store.state.innerWidth < 768
                            ? _c("ButtonFLoating", {
                                attrs: {
                                  type: "primary",
                                  shape: "circle",
                                  icon: "plus"
                                },
                                on: {
                                  "on-click": function($event) {
                                    return _vm.handleAddNew()
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("TableCustom", {
                            attrs: {
                              idtable: "table2",
                              "on-select-change": null,
                              "selected-row-keys": null,
                              "data-source": _vm.dataSourceCompanyBank,
                              columns: _vm.columnsTableCompany,
                              scroll: { x: 2200 },
                              paginationcustom: true,
                              "default-pagination": false,
                              "handle-switch": _vm.handleSwitchTable,
                              "handle-input": _vm.handleInput,
                              "handle-select": _vm.handleSelect,
                              "handle-search-select-table":
                                _vm.handleSearchSelectTable,
                              loading: _vm.loadingTableCompany
                            },
                            on: { "on-edit": _vm.reponseEditTable }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c("Pagination", {
                            attrs: {
                              total: _vm.totalElements,
                              "page-size-set": _vm.limit,
                              "id-pagination": "pagination1"
                            },
                            on: {
                              "response-pagesize-change":
                                _vm.responsePageSizeChange,
                              "response-currentpage-change":
                                _vm.responseCurrentPageChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12, align: "end" } },
                        [
                          _c("a-tag", { attrs: { color: "grey" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("lbl_total_found")) +
                                " : " +
                                _vm._s(_vm.totalElements) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            "mask-closable": false,
            keyboard: false,
            title: _vm.$t("lbl_edit_master_bank")
          },
          model: {
            value: _vm.visibleMasterBank,
            callback: function($$v) {
              _vm.visibleMasterBank = $$v
            },
            expression: "visibleMasterBank"
          }
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                layout: "vertical",
                form: _vm.formModalBankMaster,
                "label-col": { span: 8 },
                "wrapper-col": { span: 14 }
              }
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.name.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.name.decorator,
                        expression: "formRules.name.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.name.name,
                      placeholder: _vm.formRules.name.placeholder,
                      autocomplete: "off"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.code.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.code.decorator,
                        expression: "formRules.code.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.code.name,
                      placeholder: _vm.formRules.code.placeholder,
                      autocomplete: "off"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.country.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRules.country.decorator,
                        expression: "formRules.country.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRules.country.name,
                      placeholder: _vm.formRules.country.placeholder,
                      autocomplete: "off"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRules.active.label } },
                [
                  _c("a-switch", {
                    model: {
                      value: _vm.isActiveMasterBank,
                      callback: function($$v) {
                        _vm.isActiveMasterBank = $$v
                      },
                      expression: "isActiveMasterBank"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.handleReset } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
              ]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("update")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "a-modal",
        {
          attrs: {
            "mask-closable": false,
            keyboard: false,
            title: _vm.titleCompanyBank
          },
          model: {
            value: _vm.visibleCompanyBank,
            callback: function($$v) {
              _vm.visibleCompanyBank = $$v
            },
            expression: "visibleCompanyBank"
          }
        },
        [
          _c(
            "a-form",
            {
              attrs: {
                layout: "vertical",
                form: _vm.formModalCompanyBank,
                "label-col": { span: 12 },
                "wrapper-col": { span: 12 }
              },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitFormCompany.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRulesCompanyBank.bankName.label } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRulesCompanyBank.bankName.decorator,
                          expression: "formRulesCompanyBank.bankName.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRulesCompanyBank.bankName.name,
                        placeholder:
                          _vm.formRulesCompanyBank.bankName.placeholder,
                        "show-search": true,
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        loading: _vm.loadingMasterBank,
                        "allow-clear": true
                      },
                      on: {
                        search: function(value) {
                          return _vm.getListMasterBankForCompany(value)
                        }
                      }
                    },
                    _vm._l(_vm.dataBank.data, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.name } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.name) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.name) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.formRulesCompanyBank.bankBranchName.label
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value:
                          _vm.formRulesCompanyBank.bankBranchName.decorator,
                        expression:
                          "formRulesCompanyBank.bankBranchName.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRulesCompanyBank.bankBranchName.name,
                      placeholder:
                        _vm.formRulesCompanyBank.bankBranchName.placeholder,
                      autocomplete: "off"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRulesCompanyBank.country.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRulesCompanyBank.country.decorator,
                        expression: "formRulesCompanyBank.country.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRulesCompanyBank.country.name,
                      placeholder: _vm.formRulesCompanyBank.country.placeholder,
                      autocomplete: "off"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.formRulesCompanyBank.bankAccountNumber.label
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value:
                          _vm.formRulesCompanyBank.bankAccountNumber.decorator,
                        expression:
                          "formRulesCompanyBank.bankAccountNumber.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRulesCompanyBank.bankAccountNumber.name,
                      placeholder:
                        _vm.formRulesCompanyBank.bankAccountNumber.placeholder,
                      autocomplete: "off"
                    },
                    on: { keydown: _vm.letterKeydown }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.formRulesCompanyBank.bankAccountName.label
                  }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value:
                          _vm.formRulesCompanyBank.bankAccountName.decorator,
                        expression:
                          "formRulesCompanyBank.bankAccountName.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRulesCompanyBank.bankAccountName.name,
                      placeholder:
                        _vm.formRulesCompanyBank.bankAccountName.placeholder,
                      autocomplete: "off"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRulesCompanyBank.currency.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRulesCompanyBank.currency.decorator,
                        expression: "formRulesCompanyBank.currency.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRulesCompanyBank.currency.name,
                      placeholder:
                        _vm.formRulesCompanyBank.currency.placeholder,
                      autocomplete: "off"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.formRulesCompanyBank.bankAccountControl.label
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value:
                            _vm.formRulesCompanyBank.bankAccountControl
                              .decorator,
                          expression:
                            "formRulesCompanyBank.bankAccountControl.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRulesCompanyBank.bankAccountControl.name,
                        placeholder:
                          _vm.formRulesCompanyBank.bankAccountControl
                            .placeholder,
                        "show-search": true,
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        loading: _vm.loadingCoa,
                        "allow-clear": true
                      },
                      on: {
                        search: function(value) {
                          return _vm.getListOfCoa(value)
                        }
                      }
                    },
                    _vm._l(_vm.dataCoa.data, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.id } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(data.code) +
                                    " - " +
                                    _vm._s(data.description) +
                                    " "
                                )
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(data.code) +
                                  " - " +
                                  _vm._s(data.description) +
                                  " "
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRulesCompanyBank.bankType.label)
                  }
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: _vm.formRulesCompanyBank.bankType.decorator,
                          expression: "formRulesCompanyBank.bankType.decorator"
                        }
                      ],
                      attrs: {
                        name: _vm.formRulesCompanyBank.bankType.name,
                        placeholder: _vm.$t(
                          _vm.formRulesCompanyBank.bankType.placeholder
                        ),
                        "show-search": true,
                        "option-filter-prop": "children",
                        "filter-option": _vm.filterOption,
                        "allow-clear": true
                      },
                      on: {
                        search: function(value) {
                          return _vm.getListBankType()
                        }
                      }
                    },
                    _vm._l(_vm.dataBankType, function(data, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: data.value } },
                        [
                          _c(
                            "a-tooltip",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(" " + _vm._s(data.value) + " ")
                              ]),
                              _vm._v(" " + _vm._s(data.value) + " ")
                            ],
                            2
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: _vm.$t(_vm.formRulesCompanyBank.balance.label)
                  }
                },
                [
                  _c("a-input-number", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRulesCompanyBank.balance.decorator,
                        expression: "formRulesCompanyBank.balance.decorator"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      name: _vm.formRulesCompanyBank.balance.name,
                      placeholder: _vm.$t(
                        _vm.formRulesCompanyBank.balance.placeholder
                      ),
                      autocomplete: "off",
                      formatter: _vm.formatterNumber,
                      parser: _vm.reverseFormatNumber,
                      min: 0
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRulesCompanyBank.swiftCode.label } },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: _vm.formRulesCompanyBank.swiftCode.decorator,
                        expression: "formRulesCompanyBank.swiftCode.decorator"
                      }
                    ],
                    attrs: {
                      name: _vm.formRulesCompanyBank.swiftCode.name,
                      placeholder:
                        _vm.formRulesCompanyBank.swiftCode.placeholder,
                      autocomplete: "off"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRulesCompanyBank.payables.label } },
                [
                  _c("a-switch", {
                    model: {
                      value: _vm.isPayable,
                      callback: function($$v) {
                        _vm.isPayable = $$v
                      },
                      expression: "isPayable"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: { label: _vm.formRulesCompanyBank.receivables.label }
                },
                [
                  _c("a-switch", {
                    model: {
                      value: _vm.isReceivables,
                      callback: function($$v) {
                        _vm.isReceivables = $$v
                      },
                      expression: "isReceivables"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: _vm.formRulesCompanyBank.active.label } },
                [
                  _c("a-switch", {
                    model: {
                      value: _vm.isActiveCompany,
                      callback: function($$v) {
                        _vm.isActiveCompany = $$v
                      },
                      expression: "isActiveCompany"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.handleCancelModalCompany } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
              ]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitFormCompany }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }