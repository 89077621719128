




















































































































































































































































































































































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { NewResponseBank, RequestCompanyBank, RequestMasterBank, ResponseBank } from "@/models/interface/master.interface";
import { masterServices } from "@/services/master.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { settingsServices } from "@/services/settings.service";
import { CustomDataCoa, ResponseListOfCoa } from "@/models/interface/settings.interface";
import { debounceProcess } from "@/helpers/debounce";
import { ResponseListMasterType } from "@/models/interface-v2/master.interface";
import { masterTypeService } from "@/services-v2/master-type.service";
import MNotificationVue from "@/mixins/MNotification.vue";
import { formatterNumber, reverseFormatNumber, letterKeydown } from "@/validator/globalvalidator";
import { ListCompanyBank, RequestCompanyBankCreate } from "@/models/interface-v2/company-bank.interface";
import { mapGetters } from "vuex";
import { getauthorities } from "@/helpers/cookie";

interface DataSourceCompanyBank {
  id: string;
  bankName : string;
  bankBranch : string;
  bankAccNumber : string;
  bankAccName : string;
  currency : string;
  bankAccControlId : string;
  swiftCode : string;
  payables : boolean;
  receivables : boolean;
  country : string;
  active : boolean;
  key: number;
  disabledSwitch: boolean,
  disabledInput: boolean
  disabledSelect: boolean
  type: string;
  balance: number;
}
interface Record {
  data: DataSourceCompanyBank,
  index: undefined
}
export default Vue.extend({
  name: "Bank",
  mixins: [
    MNotificationVue,
  ],
  data () {
    this.getListOfCoa = debounceProcess(this.getListOfCoa, 200);
    this.getListMasterBank = debounceProcess(this.getListMasterBank, 200);
    this.getListMasterBankForCompany = debounceProcess(this.getListMasterBankForCompany, 200);
    return {
      titleCompanyBank: "" as string,
      dataBank: {} as NewResponseBank,
      dataCoa: {} as ResponseListOfCoa,
      dataBankType: [] as ResponseListMasterType[],
      mode: "create" as "create" | "update",
      visibleMasterBank: false as boolean,
      visibleCompanyBank: false as boolean,
      loadingTableMaster: false as boolean,
      loadingTableCompany: false as boolean,
      isPayable: true as boolean,
      isReceivables: true as boolean,
      isActiveMasterBank: true as boolean,
      isActiveCompany: true as boolean,
      page: 1 as number,
      pageBank: 1 as number,
      limit: 10 as number,
      limitBank: 10 as number,
      totalElementsBank: 0 as number,
      totalElements: 0 as number,
      search: "" as string,
      searchCompanyBank: "" as string,
      loadingCreate: false as boolean,
      loadingUpdate: false as boolean,
      loadingCoa: false as boolean,
      loadingMasterBank: false as boolean,
      idBankMaster: "" as string,
      isActive: true as boolean,
      selectedRowKeys: [],
      form: this.$form.createForm(this, { name: "masterBank" }),
      formModalBankMaster: this.$form.createForm(this, { name: "masterBankModal" }),
      formModalCompanyBank: this.$form.createForm(this, { name: "companyBankModal" }),
      formRulesCompanyBank: {
        bankName: {
          label: this.$t("lbl_bank_name"),
          name: "bankName",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "bankName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        bankBranchName: {
          label: this.$t("lbl_bank_branch_name"),
          name: "bankBranchName",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "bankBranchName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        country: {
          label: this.$t("lbl_country"),
          name: "country",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "country",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        bankAccountNumber: {
          label: this.$t("lbl_bank_account_number"),
          name: "bankAccountNumber",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "bankAccountNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        bankAccountName: {
          label: this.$t("lbl_bank_account_name"),
          name: "bankAccountName",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "bankAccountName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        currency: {
          label: this.$t("lbl_currency"),
          name: "currency",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "currency",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        bankAccountControl: {
          label: this.$t("lbl_bank_account_control"),
          name: "bankAccountControl",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "bankAccountControl",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        swiftCode: {
          label: this.$t("lbl_swift_code"),
          name: "swiftCode",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "swiftCode",
          ],
        },
        bankType: {
          label: "lbl_bank_type",
          name: "bankType",
          placeholder: "lbl_choose",
          decorator: [
            "bankType",
            {
              rules: [
                {
                  required: true,
                  message: () => this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        balance: {
          label: "lbl_balance",
          name: "balance",
          placeholder: "lbl_type_here",
          decorator: [
            "balance",
            {
              rules: [
                {
                  required: true,
                  message: () => this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        payables: {
          label: this.$t("lbl_payable"),
        },
        receivables: {
          label: this.$t("lbl_receivable"),
        },
        active: {
          label: this.$t("lbl_active"),
        },
      },
      formRules: {
        name: {
          label: this.$t("lbl_bank_name"),
          name: "name",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        code: {
          label: this.$t("lbl_bank_code"),
          name: "code",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        country: {
          label: this.$t("lbl_country"),
          name: "country",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "country",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        active: {
          label: this.$t("lbl_active"),
          name: "active"
        },
      },
      columnsTableCompany: [
        {
          title: this.$t("lbl_bank_name"),
          dataIndex: "bankName",
          key: "bankName",
          scopedSlots: { customRender: "bankName" },
          responsiveColSelect: [
            {
              name: "bankName",
              placeholder: this.$t("lbl_bank_name"),
              style: "width: 100%;",
              disabled: "",
              value: "name",
              options: [] as ResponseBank[],
              loading: false,
            },
            {
              name: "bankAccControlId",
              placeholder: this.$t("lbl_bank_account_control"),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as CustomDataCoa[],
              loading: false,
            },
          ],
          responsiveColInput: [
            {
              name: "bankBranch",
              placeholder: this.$t("lbl_bank_branch_name"),
              style: "width: 100%;",
            },
            {
              name: "bankAccName",
              placeholder: this.$t("lbl_bank_account_name"),
              style: "width: 100%;",
            },
            {
              name: "bankAccNumber",
              placeholder: this.$t("lbl_bank_account_number"),
              style: "width: 100%;",
            },
            {
              name: "country",
              placeholder: this.$t("lbl_country"),
              style: "width: 100%;",
            },
            {
              name: "currency",
              placeholder: this.$t("lbl_currency"),
              style: "width: 100%;",
            },
          ],
          responsiveColSwitch: [
            {
              name: "payables",
              placeholder: this.$t("lbl_payable"),
            },
            {
              name: "receivables",
              placeholder: this.$t("lbl_receivable"),
            },
            {
              name: "active",
              placeholder: this.$t("lbl_active"),
            },
          ]
        },
        {
          title: this.$t("lbl_bank_branch_name"),
          dataIndex: "bankBranch",
          key: "bankBranch",
          scopedSlots: { customRender: "bankBranch" },
        },
        {
          title: this.$t("lbl_country"),
          dataIndex: "country",
          key: "country",
          scopedSlots: { customRender: "country" },
        },
        {
          title: this.$t("lbl_bank_account_number"),
          dataIndex: "bankAccNumber",
          key: "bankAccNumber",
          scopedSlots: { customRender: "bankAccNumber" },
        },
        {
          title: this.$t("lbl_bank_account_name"),
          dataIndex: "bankAccName",
          key: "bankAccName",
          scopedSlots: { customRender: "bankAccName" },
        },
        {
          title: this.$t("lbl_currency"),
          dataIndex: "currency",
          key: "currency",
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$t("lbl_bank_account_control"),
          dataIndex: "bankAccControlId",
          key: "bankAccControlId",
          scopedSlots: { customRender: "bankAccControlId" },
        },
        {
          title: this.$t("lbl_payable"),
          dataIndex: "payables",
          key: "payables",
          scopedSlots: { customRender: "payables" },
        },
        {
          title: this.$t("lbl_receivable"),
          dataIndex: "receivables",
          key: "receivables",
          scopedSlots: { customRender: "receivables" },
        },
        {
          title: this.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "active" },
        },
        {
          title: this.$t("lbl_bank_type"),
          dataIndex: "type",
          key: "type",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_balance"),
          dataIndex: "balance",
          key: "balance",
          scopedSlots: { customRender: "currency" },
        },
        // {
        //   title: this.$t("lbl_action"),
        //   dataIndex: "operation",
        //   width: "120px",
        //   align: "center",
        //   scopedSlots: { customRender: "operation" },
        //   fixed:"right",
        //   button: ["update"],
        // },
      ] as any[],
      dataSourceCompanyBank: [] as DataSourceCompanyBank[],
      columnsTableMaster: [
        {
          title: this.$t("lbl_bank"),
          dataIndex: "name",
          key: "name",
        },
        {
          title: this.$t("lbl_country"),
          dataIndex: "country",
          key: "country",
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "isEnable" },
        },
        // {
        //   title: this.$t("lbl_action"),
        //   dataIndex: "operation",
        //   width: "120px",
        //   align: "center",
        //   scopedSlots: { customRender: "operation" },
        //   fixed:"right",
        //   button: ["update"]
        // },
      ] as any[],
      dataSource: [] as ResponseBank[],
      authPrivilege: [] as string[],
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "bank" && x.privilege.update && x.privilege.create);
    },
  },
  created () {
    this.setAuthorities();
    this.getListMasterBank();
    this.getListCompanyBank();
    this.getListOfCoa("");
    this.getListMasterBankForCompany("");
    this.getListBankType();
  },
  methods: {
    letterKeydown,
    formatterNumber,
    reverseFormatNumber,
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "bank") {
          this.authPrivilege = dataAuth.value;
          this.columnsTableCompany.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: "120px",
              align: "center",
              fixed: "right",
            },
          );
          this.columnsTableMaster.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: "120px",
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    responseEditTableMaster(response){
      this.visibleMasterBank = true;
      this.idBankMaster = response.data.id;
      this.isActiveMasterBank = response.data.active;
      setTimeout(() => {
        this.formModalBankMaster.setFieldsValue({
          name: response.data.name,
          code: response.data.code,
          country: response.data.country
        });
      }, 500);
    },
    resetFilter () {
      this.search = "";
      this.getListMasterBank();
    },
    resetFilterCompanyBank () {
      this.searchCompanyBank = "";
      this.getListCompanyBank();
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListCompanyBank();
    },
    responsePageSizeChangeBank(response: ResponsePagination): void {
      this.limitBank = response.size;
      this.pageBank = 1;
      this.getListMasterBank();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListCompanyBank();
    },
    responseCurrentPageChangeBank(response: ResponsePagination): void {
      this.pageBank = response.page;
      this.getListMasterBank();
    },
    handleAddNew () {
      this.visibleCompanyBank = true;
      this.mode = "create";
      this.formModalCompanyBank.resetFields();
      this.titleCompanyBank = this.$t("lbl_create_new") as string;
    },
    reponseEditTable (record: Record) {
      this.visibleCompanyBank = true;
      this.idBankCompanyForUpdate = record.data.id;
      this.isPayable = record.data.payables;
      this.isReceivables = record.data.receivables;
      this.isActiveCompany = record.data.active;
      this.titleCompanyBank = this.$t("lbl_edit_company_bank") as string;
      this.mode = "update";
      let params = {
        page: 0,
        limit: 10,
        search: "isParent~false"
      } as RequestQueryParamsModel;
      if(record.data.bankAccControlId) params.search = `code~*${record.data.bankAccControlId}*_OR_description~*${record.data.bankAccControlId}*_AND_` + params.search;
        // get coa
      this.loadingCoa = true;
      settingsServices.listOfCoa(params, "")
        .then((data) => {
          this.dataCoa = data;
          const idtimeout = setTimeout(() => {
            this.formModalCompanyBank.setFieldsValue({
              bankName: record.data.bankName,
              bankBranchName: record.data.bankBranch,
              country: record.data.country,
              bankAccountNumber: record.data.bankAccNumber,
              bankAccountName: record.data.bankAccName,
              currency: record.data.currency,
              bankAccountControl: data.data[0].id,
              swiftCode: record.data.swiftCode,
              bankType: record.data.type,
              balance: record.data.balance
            });
            clearTimeout(idtimeout);
          }, 500);
        })
        .finally(() => {
          this.loadingCoa = false;
        });
    },
    handleSwitchTable (checked, key, columnName) {
      this.dataSourceCompanyBank[key] = {...this.dataSourceCompanyBank[key], [columnName]: checked};
      this.dataSourceCompanyBank = this.dataSourceCompanyBank.slice();
    },
    handleInput (value, key, column, columnName) {
      this.dataSourceCompanyBank[key] = {...this.dataSourceCompanyBank[key], [columnName]: value};
      this.dataSourceCompanyBank = this.dataSourceCompanyBank.slice();
    },
    handleSelect (value, key, columnName) {
      this.dataSourceCompanyBank[key][columnName] = value;
      this.dataSourceCompanyBank = this.dataSourceCompanyBank.slice();
    },
    handleSearchSelectTable (value, key, columnName) {
      if(columnName === "bankAccControlId") {
        this.getListOfCoa(value);
      } else if (columnName === "bankName") {
        this.search = value;
        this.getListMasterBank();
      }
    },
    getListMasterBankForCompany (value, bankName?) {
      let params = {
        page: this.pageBank - 1,
        limit: this.limitBank
      } as RequestQueryParamsModel;
      if(value) params.search = `name~*${value}*_OR_code~*${value}*_OR_country~*${value}*`;
      if(bankName) params.search = `name~*${bankName}*`;

      this.loadingMasterBank = true;
      masterServices
        .listMasterBank(params)
        .then(data => {
          if(bankName) {
            this.dataBank = { ...this.dataBank, data: [...this.dataBank.data, ...data.data ] };
          } else {
            this.dataBank = data;
          }
        })
        .finally(() => {
          this.loadingMasterBank = false;
        });
    },
    getListMasterBank () {
      let params = {
        page: this.pageBank - 1,
        limit: this.limitBank
      } as RequestQueryParamsModel;
      if(this.search) params.search = `name~*${this.search}*_OR_code~*${this.search}*_OR_country~*${this.search}*`;

      this.loadingTableMaster = true;
      this.loadingTableCompany = true;
      masterServices
        .listMasterBank(params)
        .then(data => {
          data.data.forEach((el, i) => el["key"] = i);
          this.dataSource = data.data;
          this.totalElementsBank = data.totalElements;
          this.columnsTableCompany = this.columnsTableCompany.slice();
        })
        .finally(() => {
          this.loadingTableMaster = false;
          this.loadingTableCompany = false;
        });
    },
    getListOfCoa (value, id?) {
      let params = {
        page: 0,
        limit: 10,
        search: "isParent~false"
      } as RequestQueryParamsModel;
      if(value) params.search = `code~*${value}*_OR_description~*${value}*_AND_` + params.search;
      if(id) params.search = `_AND_secureId~*${id}*`;

        // list coa
      this.loadingCoa = true;
      settingsServices
        .listOfCoa(params, "")
        .then((data) => {
          if(id) {
            this.dataCoa = { ...this.dataCoa, data: [...this.dataCoa.data, ...data.data ] };
          } else {
            this.dataCoa = data;
          }
        })
        .finally(() => {
          this.loadingCoa = false;
        });
    },
    getListCompanyBank () {
      let params = {
        limit: this.limit,
        page: this.page - 1
      } as RequestQueryParamsModel;
      if(this.searchCompanyBank) params.search = `bankAccName~*${this.searchCompanyBank}*_OR_bankAccNumber~*${this.searchCompanyBank}*_OR_bankBranch~*${this.searchCompanyBank}*_OR_bankName~*${this.searchCompanyBank}*_OR_country~*${this.searchCompanyBank}*_OR_currency~*${this.searchCompanyBank}*`;
      this.loadingTableCompany = true;
      masterServices.listCompanyBank(params)
        .then(data => {
          let tempData = [] as DataSourceCompanyBank[];
          this.totalElements = data.totalElements;
          data.data.forEach((dataMap: ListCompanyBank, index) => {
            tempData = [...tempData, {
              key: index,
              id: dataMap.id,
              bankName: dataMap.bankName,
              bankBranch: dataMap.bankBranch,
              bankAccNumber: dataMap.bankAccNumber,
              bankAccName: dataMap.bankAccName,
              bankAccControlId: dataMap.bankAccControl.description,
              currency: dataMap.currency,
              swiftCode: dataMap.swiftCode,
              payables: dataMap.payables,
              receivables: dataMap.receivables,
              country: dataMap.country,
              active: dataMap.active,
              disabledSwitch: true,
              disabledInput: true,
              disabledSelect: true,
              type: dataMap.type,
              balance: dataMap.balance,
            }];
          });
          this.dataSourceCompanyBank = tempData;
        })
        .finally(() => {
          this.loadingTableCompany = false;
        });
    },
    reponseSearchInput(response): void {
      this.search = response ? response : "";
      this.getListMasterBank();
    },
    reponseSearchInputCompanyBank(response): void {
      this.searchCompanyBank = response ? response : "";
      this.getListCompanyBank();
    },
    createNewCompanyBank(payload): void {
      masterServices.createCompanyBank(payload)
        .then(() => {
          this.visibleCompanyBank = false;
          this.$message.success(this.$t("notif_create_success").toString());
          this.getListCompanyBank();
        })
        .catch(() => this.$message.error(this.$t("notif_create_fail").toString()))
        .finally(() => this.loadingTableCompany = false);
    },
    updateCompanyBank(payload): void {
      masterServices.updateCompanyBank(payload, this.idBankCompanyForUpdate)
        .then(() => {
          this.visibleCompanyBank = false;
          this.$message.success(this.$t("notif_update_success").toString());
          this.getListCompanyBank();
          this.getListOfCoa("");
        })
        .catch(() => this.$message.error(this.$t("notif_update_fail").toString()))
        .finally(() => this.loadingTableCompany = false);
    },
    submitFormCompany () {
      this.formModalCompanyBank.validateFields((err, res) => {
        if(err) return;
        const payload: RequestCompanyBankCreate = {
          bankName: res.bankName,
          bankBranch: res.bankBranchName,
          bankAccNumber: res.bankAccountNumber,
          bankAccName: res.bankAccountName,
          currency: res.currency,
          bankAccControlId: res.bankAccountControl,
          swiftCode: res.swiftCode ? res.swiftCode : null,
          payables : this.isPayable,
          receivables : this.isReceivables,
          country: res.country,
          active : this.isActiveCompany,
          type: res.bankType,
          balance: res.balance,
        };
        if (this.mode === "create") {
          this.createNewCompanyBank(payload);
        } else {
          this.updateCompanyBank(payload);
        }
      });
    },
    createNewMasterBank(payload): void {
      this.loadingCreate = true;
      masterServices
        .createMasterBank(payload)
        .then(() => {
          this.$message.success(this.$t("notif_create_success").toString());
          this.form.resetFields();
          this.idBankMaster = "";
          this.getListMasterBank();
        })
        .catch(() => this.$message.error(this.$t("notif_create_fail").toString()))
        .finally(() => this.loadingCreate = false);
    },
    updateMasterBank(payload): void {
      this.loadingUpdate = true;
      masterServices
        .updateMasterBank(payload, this.idBankMaster)
        .then(() => {
          this.$message.success(this.$t("notif_update_success").toString());
          this.form.resetFields();
          this.idBankMaster = "";
          this.visibleMasterBank = false;
          this.getListMasterBank();
        })
        .catch(() => this.$message.error(this.$t("notif_update_fail").toString()))
        .finally(() => this.loadingUpdate = false);
    },
    submitForm(mode: "create" | "update"): void {
      if (mode === "create") {
        this.form.validateFields((err, res) => {
          if (err) return;
          const payload = {
          name: res.name,
          code: res.code,
          country: res.country,
          active: this.isActive,
        } as RequestMasterBank;
          this.createNewMasterBank(payload);
        });
      } else {
        this.formModalBankMaster.validateFields((err, res) => {
          if (err) return;
          if (this.idBankMaster) {
            const payload = {
              name: res.name,
              code: res.code,
              country: res.country,
              active: this.isActiveMasterBank,
            } as RequestMasterBank;
            this.updateMasterBank(payload);
          } else {
            this.$notification.error({
              message: this.$t("lbl_error_title").toString(),
              description: this.$t("lbl_error_no_selected_data").toString()
            });
          }
        });
      }
    },
    handleCancelModalCompany():void {
      this.visibleCompanyBank = false;
      if(this.mode === "update") {
        this.getListOfCoa("");
      }
    },
    handleReset(): void {
      this.form.resetFields();
      this.idBankMaster = "";
      this.visibleMasterBank = false;
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async getListBankType(): Promise<void> {
      try {
        const res = await masterTypeService.listMaster({name: "COMPANY_BANK_TYPE"});
        this.dataBankType = res;
      } catch (error) {
        this.showNotifError("notif_process_fail");
      }
    },
  },
});
